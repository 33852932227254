<template>
  
    <router-view/>
  
</template>

<style>
html,body,#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  color: #2c3e50;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}

#app {
  display: flex;
}
.el-menu-item.is-active{
  color:#e5ebf1 !important;
  background-color: #398ce2 !important;
}
.router-view {
  width: 100%;
  height: auto
}

.sel_cate_name{
    display: block;
}
.sel_cate_name.depth_1{
    text-indent:14px;
}
.sel_cate_name.depth_2{
    text-indent:28px;
}
.sel_cate_name.depth_3{
    text-indent:42px;
}
.f-right {
  float: right;
}
</style>
<script>
import Cookies from 'js-cookie'
import constant from "@/constant"
export default {
  created:function(){
    console.log("start now");
    let cur_id = Cookies.get("id");
    let cur_role = Cookies.get("role");
    console.log("cur_id--" + cur_id);
    console.log("cur_role--" + cur_role);
    this.axios.get(constant.updatesession,{
      headers:{
        'Content-Type': 'application/json'
      },
      params:{
        curid:cur_id,
        currole:cur_role,
      }
    }).then((response)=>{
      console.log("updatesession--"+response.data);
      console.log(response.data);
    })
  }
}
</script>