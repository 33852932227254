import { createRouter, createWebHashHistory } from 'vue-router'
import constant from "@/constant"
import Layout from "@/layout/Layout"
import Cookies from 'js-cookie'

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: {title: "用户登录", requireAuth: false},
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/main',
    component: Layout,
    icon: "el-icon-s-home",
    meta: {title: constant.title, requireAuth: true},
    redirect: '/home',
    children: [
        {
            path: "/home",
            name: "工作台",
            icon: "el-icon-s-home",
            meta: {title: "工作台", requireAuth: true},
            component: () => import('@/views/Home.vue')
        }
    ]
  },
  // {
  //   path: '/main2',
  //   component: Layout,
  //   name:"个人资料",
  //   meta: {title: constant.title, requireAuth: true},
  //   redirect:"/personinfo",
  //   children: [
  //       {
  //         path:"/personinfo",
  //         name:"个人资料",
  //         icon: "el-icon-user",
  //         meta: {title: "个人资料", requireAuth: true},
  //         component: () => import('@/views/PersonInfo.vue')
  //       }
  //   ]
  // },
  // {
  //   path: '/merchantinfo',
  //   component: Layout,
  //   name:"商铺资料",
  //   meta: {title: constant.title, requireAuth: true},
  //   redirect:"/merchantinfo",
  //   children: [
  //       {
  //         path:"/merchantinfo",
  //         name:"商铺资料",
  //         icon: "el-icon-user",
  //         meta: {title: "个人资料", requireAuth: true},
  //         component: () => import('@/views/MerchantInfo.vue')
  //       }
  //   ]
  // },
  {
    path: '/permission',
    component: Layout,
    name:'权限管理',
    icon: "el-icon-user",
    meta: {title: '权限管理', requireAuth: true},
    redirect: '/role',
    children: [
        {
            path: "/role",
            name: "账号角色管理",
            
            meta: {title: "账号角色管理", requireAuth: true, show: true},
            component: () => import('@/views/Role.vue')
        },
        {
          path: "/operator",
          name: "人员列表",
          
          meta: {title: "人员列表", requireAuth: true, show: true},
          component: () => import('@/views/Operator.vue')
        },
    ]
  },
  {
    path:'/merchant',
    name:'商户管理',
    component: Layout,
    icon: "el-icon-s-custom",
    meta: {title: "商户管理", requireAuth: true},
    children:[
      {
        path: "/merchantlist",
        name: "商户列表",
        
        meta: {title: "商户列表", requireAuth: true, show: true},
        component: () => import('@/views/Merchant.vue')
      },
      {
        path: "/addmerchant",
        name: "新增商户",
        meta: {title: "新增商户", requireAuth: true, show: false},
        component: () => import('@/views/AddMerchant.vue')
      },
      {
        path:"/merchantnew",
        name:"商家入驻",
        meta:{title:"商家入驻",requireAuth:true,show:true},
        component:()=>import('@/views/Merchantruzhu.vue')
      }
    ]
  },
  {
    path:'/product',
    name:'商品管理',
    component: Layout,
    icon: "el-icon-goods",
    meta: {title: "商品管理", requireAuth: true},
    children:[
      {
        path: "/pdt",
        name: "商品管理",
        
        meta: {title: "商品管理", requireAuth: true, show: true},
        component: () => import('@/views/Product.vue')
      },
      {
        path:"/productdetail",
        name:"商品详情页管理",
        meta: {title: "商品详情页管理", requireAuth: true, show: false},
        component: () => import('@/views/ProductDetail.vue')
      },
      // {
      //   path: "/planlist",
      //   name: "套餐管理",
      //   meta: {title: "套餐管理", requireAuth: true, show: true},
      //   component: () => import('@/views/Plan.vue')
      // },
      // {
      //   path: "/stock",
      //   name: "库存管理",
      //   meta: {title: "库存管理", requireAuth: true, show: true},
      //   component: () => import('@/views/Stock.vue')
      // },
      
      {
        path: "/addplan",
        name: "新增套餐",
        meta: {title: "新增套餐", requireAuth: true, show: false},
        component: () => import('@/views/AddPlanv2.vue')
      },
      {
        path: "/editplan",
        name: "编辑套餐",
        meta: {title: "编辑套餐", requireAuth: true},
        component: () => import('@/views/EditPlan.vue')
      },
      
      
      {
        path: "/addpdt",
        name: "新增商品",
        
        meta: {title: "新增商品", requireAuth: true, show: false},
        component: () => import('@/views/AddProduct.vue')
      },
    ]
  },
  
  {
    path:'/info',
    name:'基本信息管理',
    component: Layout,
    icon: "el-icon-eleme",
    meta: {title: "基本信息管理", requireAuth: true},
    children:[
      {
        path: "/category",
        name: "品类管理",
        
        meta: {title: "品类管理", requireAuth: true, show: true},
        component: () => import('@/views/Category.vue')
      },
      {
        path: "/brand",
        name: "品牌管理",
        
        meta: {title: "品牌管理", requireAuth: true, show: true},
        component: () => import('@/views/Brand.vue')
      },
      
      {
        path: "/model",
        name: "型号管理",
        
        meta: {title: "型号管理", requireAuth: true, show: true},
        component: () => import('@/views/Model.vue')
      },
      {
        path: "/param",
        name: "参数管理",
        
        meta: {title: "参数管理", requireAuth: true, show: true},
        component: () => import('@/views/Param.vue')
      },
    ]
  },
  
  {
    path:'/information',
    name: "通用信息管理",
    component: Layout,
    icon: "el-icon-info",
    meta: {title: constant.title, requireAuth: true},
    children: [
      {
          path: "/template",
          name: "模板管理",
          meta: {title: "模板管理", requireAuth: true, show: true},
          component: () => import('@/views/Template.vue')
      },
      {
        path: "/notice",
        name: "公告管理",
        meta: {title: "公告管理", requireAuth: true, show: true},
        component: () => import('@/views/Notice.vue')
      },
      {
        path: "/guarant",
        name: "保障服务",
        meta: {title: "保障服务", requireAuth: true, show: true},
        component: () => import('@/views/Guarant.vue')
      },
      {
        path: "/addguarant",
        name: "编辑保障服务",
        meta: {title: "编辑保障服务", requireAuth: true, show: false},
        component: () => import('@/views/AddGuarant.vue')
      }
    ]
  },
  {
    path:'/order',
    name: "订单管理",
    component: Layout,
    icon: "el-icon-document-copy",
    meta: {title: constant.title, requireAuth: true},
    children: [
      {
          path: "/orderlist",
          name: "订单管理",
          meta: {title: "订单管理", requireAuth: true, show: true},
          component: () => import('@/views/Order.vue')
      },
      {
        path: "/orderdetail",
        name: "订单详情",
        meta: {title: "订单详情", requireAuth: true},
        component: () => import('@/views/Orderdetail.vue')
    },
      {
        path: "/buyout",
        name: "申请买断管理",
        meta: {title: "申请买断管理", requireAuth: true, show: true},
        component: () => import('@/views/Buyout.vue')
      },
      {
        path: "/overdue",
        name: "逾期订单管理",
        meta: {title: "逾期订单管理", requireAuth: true, show: false},
        component: () => import('@/views/Overdue.vue')
      },
      
      {
        path: "/associate",
        name: "关联管理",
        meta: {title: "关联管理", requireAuth: true, show: true},
        component: () => import('@/views/Associate.vue')
      },
      
      {
        path: "/addassociate",
        name: "新建关联",
        meta: {title: "新建关联", requireAuth: true, show: false},
        component: () => import('@/views/AddAssociate.vue')
      },
      {
        path: "/allocate",
        name: "调拨管理",
        meta: {title: "调拨管理", requireAuth: true, show: true},
        component: () => import('@/views/Allocate.vue')
      }
    ]
  },
  
  {
    path:'/finance',
    name: "财务管理",
    component: Layout,
    icon: "el-icon-coin",
    meta: {title: constant.title, requireAuth: true},
    children: [
      {
          path: "/bill",
          name: "交易流水",
          meta: {title: "交易流水", requireAuth: true, show: true},
          component: () => import('@/views/Bill.vue')
      },
      // {
      //   path: "/gather",
      //   name: "账单管理",
      //   meta: {title: "账单管理", requireAuth: true, show: true},
      //   component: () => import('@/views/Gather.vue')
      // },

      {
        path: "/refund",
        name: "退款管理",
        meta: {title: "退款管理", requireAuth: true, show: true},
        component: () => import('@/views/Refund.vue')
      },
      {
        path: "/cashout",
        name: "提现管理",
        meta: {title: "提现管理", requireAuth: true, show: true},
        component: () => import('@/views/Cashout.vue')
      },
      {
        path:'/settle',
        name:'结算管理',
        meta:{title:'结算管理',requireAuth: true, show: true},
        component: ()=> import('@/views/Settle.vue')
      },
      {
        path:'/invoiceManager',
        name:'发票管理',
        meta:{title:'发票管理',requireAuth: true, show: true},
        component: ()=> import('@/views/InvoiceManager.vue')
      },
      {
        path:'/invoice',
        name:'申请发票',
        meta:{title:'申请发票',requireAuth: true, show: true},
        component: ()=> import('@/views/Invoice.vue')
      },
    ]
  },
  {
    path: '/setting',
    component: Layout,
    name:'前端配置',
    icon: "el-icon-setting",
    meta: {title: constant.title, requireAuth: true},
    
    children: [
      
      {
        path: '/page/index',
        name: '首页管理',
        
        meta: { title: '首页管理', requireAuth: true, channelid: '2',show: true },
        component: () => import("@/views/page/index.vue"),
        
      },
      
      {
        path: '/page/category',
        name: '分类页管理',
        
        meta: { title: '分类页管理', requireAuth: true,show: true },
        component: () => import("@/views/page/category.vue"),
        
      },
      
      {
        path: '/page/my',
        name: '个人页管理',
        
        meta: { title: '个人页管理', requireAuth: true,show: true },
        component: () => import("@/views/page/my.vue"),
        
      },
      
      {
        path: '/page/goodsmanage',
        name: '前端商品管理',
        
        meta: { title: '前端商品管理', requireAuth: true },
        component: () => import("@/views/page/goods_manage.vue"),
        
      },
      
      {
        path: '/page/contentmanage',
        name: '区域内容管理',
        
        meta: { title: '区域内容管理', requireAuth: true },
        component: () => import("@/views/page/content_manage.vue"),
       
      },
      
      {
        path: '/page/menumanage',
        name: '橱窗管理',
        
        meta: { title: '橱窗管理', requireAuth: true },
        component: () => import("@/views/page/menu_manage.vue"),
        
      }
    ]
  },
  {
    path: '/activity',
    component: Layout,
    name:'活动管理',
    icon: "el-icon-star-off",
    meta: {title: constant.title, requireAuth: true},
    
    children: [
        
        {
          path: '/page/activity',
          name: '活动页管理',
          
          meta: { title: '活动页管理', requireAuth: true, channelid: '2' ,show: true},
          component: () => import("@/views/page/activity_list.vue"),
          
        },
        {
          path: '/page/actdetail',
          name: '活动页设置',
          
          meta: { title: '活动页设置', requireAuth: true },
          component: () => import("@/views/page/activity_detail.vue"),
          
        },
        {
          path: "/coupon",
          name: "优惠券管理",
          
          meta: {title: "优惠券管理", requireAuth: true, show: true},
          component: () => import('@/views/Coupon.vue')
        },
        {
          path: "/addcoupon",
          name: "添加优惠券",
          
          meta: {title: "添加优惠券", requireAuth: true},
          component: () => import('@/views/AddCoupon.vue')
        },
        {
          path:"/productdetailsactivity",
          name:"商品活动管理",
          meta:{title:"商品详情活动",requireAuth:true,show:true},
          component:()=>import('@/views/Productdetailsactivity.vue')
        },{
          path:"/addproductdetailactivity",
          name:"添加商品详情活动",
          meta:{title:"添加商品详情活动",requireAuth:true},
          component:()=>import('@/views/Addproductdetailactivity.vue')
        },
		{
          path: "/merchantcoupon",
          name: "商家券",

          meta: {title: '商家券', requireAuth: true, show: true},
          component: () => import('@/views/Merchantcoupon.vue')
        },
        {
          path: '/addmerchantcoupon',
          name: '添加商家券',
          
          meta: {title: '添加商家券', requireAuth: true},
          component: () => import('@/views/Addmerchantcoupon.vue')
        }
    ]
  },
  // {
  //   path: '/statistic',
  //   component: Layout,
  //   name:'招商店铺数据管理',
  //   icon: "el-icon-star-off",
  //   meta: {title: constant.title, requireAuth: true},
    
  //   children: [
        
  //       {
  //         path: '/statistics',
  //         name: '统计信息',
          
  //         meta: { title: '统计信息', requireAuth: true, channelid: '2' ,show: true},
  //         component: () => import("@/views/Statistics.vue"),
          
  //       },
  //       {
  //         path: '/addstatistics',
  //         name: '添加统计信息',
          
  //         meta: { title: '添加统计信息', requireAuth: true ,show:true},
  //         component: () => import("@/views/Addstatistics.vue"),
          
  //       }
        
  //   ]
  // }
]


const router = createRouter({
  history: createWebHashHistory(),
  routes,
})
router.beforeEach((to,from,next)=>{
  console.log("to--");
  console.log(to);
  console.log(from);
  console.log(next);
  if(to.meta.title){
      document.title = to.meta.title
  }else{
      document.title = constant.title
  }
  let token = Cookies.get("token");
  console.log("token--" + token);
  let arr = Cookies.get('patharr');
  console.log("arr--" + arr);
  if(to.name != "Login"){
    if(!token){
      //没有登录过
      next({path:'/'})
    }else{
      // if(arr){
      //   arr = JSON.parse(arr);
      //   console.log("解码");
      //   console.log(arr);
      //   if(arr.indexOf(to.path) == -1){
      //     alert("该账号无权限访问");
      //     next({path:'/main'})
      //   }
      // }
      next();
    }
  }else{
    next();
  }
  
  //next();
    
})

export default router

