export default{
    
    username:'',
    title:'炫租机后台管理系统',
    //localname:'192.168.0.5//aliapp',
    //servename:'xuanzj.wbaohe.com//static/includes',
    
    //品牌
    save_brand_url:'https://merchant.xianniuzu.com/static/includes/data.php?op=savebrand',
    get_brand_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=getbrand",
    enable_brand_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=enablebrand",
    disable_brand_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=disablebrand",
    del_brand_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=delbrand",
    
    //品类
    save_category_url:'https://merchant.xianniuzu.com/static/includes/data.php?op=savecategory',
    get_category_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=getcategory",
    enable_category_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=enablecategory",
    disable_category_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=disablecategory",
    del_category_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=delcategory",
    get_tree_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=gettree",

    //型号
    save_model_url:'https://merchant.xianniuzu.com/static/includes/data.php?op=savemodel',
    get_model_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=getmodel",
    enable_model_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=enablemodel",
    disable_model_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=disablemodel",
    del_model_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=delmodel",
    get_select_model_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=getselmodel",
    
    //参数
    save_param_url:'https://merchant.xianniuzu.com/static/includes/data.php?op=saveparam',
    get_param_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=getparam",
    enable_param_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=enableparam",
    disable_param_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=disableparam",
    del_param_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=delparam",
    get_param_val_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=getparamval",
    save_param_val_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=saveparamval",
    del_param_val_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=delparamval",

    //产品
    upload_pdt_detail_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=uploadtempimg",
    upload_images:"https://merchant.xianniuzu.com/static/includes/data.php?op=uploadimages",
    //upload_pdt_detail_url:"http://www.xnzj.com/data.php?op=uploadtempimg",
    save_pdt_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=savepdt",
    get_pdt_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=getpdt",
    //get_pdt_url:"http://www.xnzj.com/data.php?op=getpdt",
    enable_pdt_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=enablepdt",
    disable_pdt_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=disablepdt",
    get_sel_pdt_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=editpdt",
    del_pdt_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=delpdt",
    get_pdt_detail_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=getpdtdetail",
    get_pdt_plan_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=getpdtplan",
    savesetprice:"https://merchant.xianniuzu.com/static/includes/data.php?op=savesetprice",
    getpdttemp:"https://merchant.xianniuzu.com/static/includes/data.php?op=getpdttemp",
    copypdt:"https://merchant.xianniuzu.com/static/includes/data.php?op=copypdt",
    getpdtparams:"https://merchant.xianniuzu.com/static/includes/data.php?op=getpdtparams",
    lockpdt:"https://merchant.xianniuzu.com/static/includes/data.php?op=lockpdt",
    unlock:"https://merchant.xianniuzu.com/static/includes/data.php?op=unlock",
    industry: "https://merchant.xianniuzu.com/static/includes/data.php?op=industrylist",
    //模板
    save_temp_url:'https://merchant.xianniuzu.com/static/includes/data.php?op=savetemp',
    get_temp_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=gettemp",
    enable_temp_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=enabletemp",
    disable_temp_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=disabletemp",
    del_temp_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=deltemp",
    upload_temp_img_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=uploadtempimg",
    upload_temp_img_url2:"https://merchant.xianniuzu.com/static/includes/data.php?op=uploadtempimglocal",
    get_temp_detail:"https://merchant.xianniuzu.com/static/includes/data.php?op=getdetail",
    //级联
    get_cascader_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=cascader",
    //套餐
    get_plan_model_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=planmodel",
    get_plan_pdt_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=planpdt",
    get_plan_param_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=planparam",
    get_sel_param_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=selparam",
    save_plan_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=saveplan",
    get_plan_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=getplan",
    get_pdt_param:"https://merchant.xianniuzu.com/static/includes/data.php?op=get_pdt_param",
    del_plan_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=delplan",
    enable_plan_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=enableplan",
    disable_plan_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=disableplan",
    edit_plan_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=editplan",
    get_plan_guarant_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=planguarant",
    save_edit_plan_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=saveedit",
    batchsave_plan_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=batchsaveplan",
    batch_add_plan_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=batchaddplan",
    check_plan_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=checkplan",
    temp_save_plan_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=tempsaveplan",
    get_example_plan_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=exampleplan",
    get_plan_list:"https://merchant.xianniuzu.com/static/includes/data.php?op=getplanlist",

    //保障服务
    save_guarant_url:'https://merchant.xianniuzu.com/static/includes/data.php?op=saveguarant',
    get_guarant_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=getguarant",
    enable_guarant_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=enableguarant",
    disable_guarant_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=disableguarant",
    del_guarant_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=delguarant",

    //公告
    save_notice_url:'https://merchant.xianniuzu.com/static/includes/data.php?op=savenotice',
    get_notice_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=getnotice",
    enable_notice_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=enablenotice",
    disable_notice_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=disablenotice",
    del_notice_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=delnotice",
    get_notice_detail:"https://merchant.xianniuzu.com/static/includes/data.php?op=getnoticedetail",

    //公用
    updatesession:"https://merchant.xianniuzu.com/static/includes/data.php?op=updatesession",
    get_rank_url:'https://merchant.xianniuzu.com/static/includes/data.php?op=getrank',
    get_cate_tree_url:'https://merchant.xianniuzu.com/static/includes/data.php?op=getcatetree',
    batch_edit_plan:"https://merchant.xianniuzu.com/static/includes/data.php?op=batchplan",
    get_region:"https://merchant.xianniuzu.com/static/includes/data.php?op=getregion",
    get_logistic_company:"https://merchant.xianniuzu.com/static/includes/data.php?op=getlogcom",
    //get_logistic_company:"http://www.xnzj.com/data.php?op=getlogcom",
    get_return_addr:"https://merchant.xianniuzu.com/static/includes/data.php?op=getreturnaddr",
    fileupload:"https://merchant.xianniuzu.com/static/includes/data.php?op=fileupload",
    uploadid:"https://merchant.xianniuzu.com/static/includes/data.php?op=uploadid",
    savelicense:"https://merchant.xianniuzu.com/static/includes/data.php?op=savelicense",
    statisticinfo:"https://merchant.xianniuzu.com/static/includes/data.php?op=homepage",

    //库存
    get_stock_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=getstock",
    save_stock_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=savestock",

    //账单
    get_bill_url: "https://merchant.xianniuzu.com/static/includes/data.php?op=getbill",
    save_offline_bill: "https://merchant.xianniuzu.com/static/includes/data.php?op=saveoffline",
    withdraw_bill_url: "https://merchant.xianniuzu.com/static/includes/data.php?op=withdrawbill",
    refundlist:'https://merchant.xianniuzu.com/static/includes/data.php?op=refundlist',
    updaterefund:"https://merchant.xianniuzu.com/static/includes/data.php?op=updaterefund",
    getoplog:"https://merchant.xianniuzu.com/static/includes/data.php?op=getoplog",
    //订单
    getproof:"https://merchant.xianniuzu.com/static/includes/data.php?op=getproof",
    uploadproof:"https://merchant.xianniuzu.com/static/includes/data.php?op=uploadproof",

    get_order_list:"https://merchant.xianniuzu.com/static/includes/data.php?op=getorderlist",
    // get_order_list:"http://www.xnz.com/data.php?op=getorderlist",
    get_order:"https://merchant.xianniuzu.com/static/includes/data.php?op=getorderprice",
    get_apply_list:"https://merchant.xianniuzu.com/static/includes/data.php?op=getapply",
    save_buyout:"https://merchant.xianniuzu.com/static/includes/data.php?op=savebuyout",
    save_cancel_order:"https://merchant.xianniuzu.com/static/includes/data.php?op=savecancel",
    //save_cancel_order:"http://www.xnzj.com/data.php?op=savecancel",
    turn_overdue:"https://merchant.xianniuzu.com/static/includes/data.php?op=turnoverdue",
    set_receive:"https://merchant.xianniuzu.com/static/includes/data.php?op=setreceive",
    set_complete:"https://merchant.xianniuzu.com/static/includes/data.php?op=setcomplete",
    set_consign:"https://merchant.xianniuzu.com/static/includes/data.php?op=setconsign",
    //set_consign:"http://www.xnzj.com/data.php?op=setconsign",
    save_logistic:'https://merchant.xianniuzu.com/static/includes/data.php?op=savelogistic',
    //save_logistic:"http://www.xnz.com/data.php?op=savelogistic",
    get_order_logistic:'https://merchant.xianniuzu.com/static/includes/data.php?op=getlogistic',
    //get_order_logistic:"http://www.xnzj.com/data.php?op=getlogistic",
    get_customer_addr:'https://merchant.xianniuzu.com/static/includes/data.php?op=getcustomeraddr',
    save_addr:'https://merchant.xianniuzu.com/static/includes/data.php?op=savecustomeraddr',
    confirm_order:'https://merchant.xianniuzu.com/static/includes/data.php?op=confirmorder',
    save_addition:"https://merchant.xianniuzu.com/static/includes/data.php?op=saveaddition",
    get_info:"https://merchant.xianniuzu.com/static/includes/data.php?op=orderdetail",
    save_offline:"https://merchant.xianniuzu.com/static/includes/data.php?op=saveoffline",
    get_logistic_detail:"https://merchant.xianniuzu.com/static/includes/data.php?op=logisticdetail",
    //get_logistic_detail:"http://www.xnz.com/data.php?op=logisticdetail",
    get_orderdetail:"https://merchant.xianniuzu.com/static/includes/data.php?op=orderdetail",
    get_order_bill:"https://merchant.xianniuzu.com/static/includes/data.php?op=orderbill",
    get_billno:"https://merchant.xianniuzu.com/static/includes/data.php?op=billno",
    // get_billno:"http://www.xnz.com/data.php?op=billno",
    save_pbi:"https://merchant.xianniuzu.com/static/includes/data.php?op=savepbi",
    // save_pbi:"http://www.xnz.com/data.php?op=savepbi",
    save_offincome:"https://merchant.xianniuzu.com/static/includes/data.php?op=saveincome",
    get_trade:"https://merchant.xianniuzu.com/static/includes/data.php?op=gettrade",
    get_decrease:"https://merchant.xianniuzu.com/static/includes/data.php?op=getordermoney",
    get_deposit:"https://merchant.xianniuzu.com/static/includes/data.php?op=getdeposit",
    set_buyoutprice:"https://merchant.xianniuzu.com/static/includes/data.php?op=setbuyout",
    confirm_ReReceive:"https://merchant.xianniuzu.com/static/includes/data.php?op=rereceive",
    get_estimate:"https://merchant.xianniuzu.com/static/includes/data.php?op=getestimate",
    get_estimate_order:"https://merchant.xianniuzu.com/static/includes/data.php?op=getestimateorder",
    save_estimate:"https://merchant.xianniuzu.com/static/includes/data.php?op=saveestimate",
    
    add_remark:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=addremark",
    //add_remark:"http://www.xnzj.com/data.php?op=addremark",
    add_evidence:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=addevidence",
    get_seller_remarks:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=viewnotes",
    //get_seller_remarks:"http://www.xnzj.com/datatwo.php?op=viewnotes",
    getev:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=getev",
    //getev:"http://www.xnzj.com/datatwo.php?op=getev",
    export:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=export",
    getevall:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=exportall",
    uploadfile:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=uploadfile",
    // uploadfile:"http://www.xnz.com/datatwo.php?op=uploadfile",
    get_seller_remark:"https://merchant.xianniuzu.com/static/includes/data.php?op=getremark",
    
    getcompesation:"https://merchant.xianniuzu.com/static/includes/data.php?op=getcompesation",
    save_mitigate:"https://merchant.xianniuzu.com/static/includes/data.php?op=savemitigate",
    getadditioninfo:"https://merchant.xianniuzu.com/static/includes/data.php?op=getadditioninfo",
    restoreorder:"https://merchant.xianniuzu.com/static/includes/data.php?op=restoreorder",
    checkreturn:"https://merchant.xianniuzu.com/static/includes/data.php?op=checkreturn",
    riskinfo:"https://merchant.xianniuzu.com/static/includes/data.php?op=riskinfo",
    savereject:"https://merchant.xianniuzu.com/static/includes/data.php?op=savereject",
    alterorder:"https://merchant.xianniuzu.com/static/includes/data.php?op=alterorder",
    savealter:"https://merchant.xianniuzu.com/static/includes/data.php?op=savealter",
    extendorder:"https://merchant.xianniuzu.com/static/includes/data.php?op=extendorder",
    saveextend:"https://merchant.xianniuzu.com/static/includes/data.php?op=saveextend",

    //系统，角色管理
    get_role_list:"https://merchant.xianniuzu.com/static/includes/data.php?op=getrolelist",
    //get_role_list:"http://www.xnzj.com/data.php?op=getrolelist",
    get_role:"https://merchant.xianniuzu.com/static/includes/data.php?op=getrole",
    set_role:"https://merchant.xianniuzu.com/static/includes/index.php?entry=role&op=saverole",
    operator_list:"https://merchant.xianniuzu.com/static/includes/data.php?op=getoplist",
    save_operator:"https://merchant.xianniuzu.com/static/includes/data.php?op=saveop",
    get_permission:"https://merchant.xianniuzu.com/static/includes/data.php?op=getpermission",
    save_role:"https://merchant.xianniuzu.com/static/includes/data.php?op=saverole",
    get_operator:'https://merchant.xianniuzu.com/static/includes/data.php?op=getopinfo',
    save_password:'https://merchant.xianniuzu.com/static/includes/data.php?op=savepassword',
    delrole:"https://merchant.xianniuzu.com/static/includes/data.php?op=delrole",
    update_status:"https://merchant.xianniuzu.com/static/includes/data.php?op=changestatus",
    delop:"https://merchant.xianniuzu.com/static/includes/data.php?op=delop",
    //登录
    login_url:"https://merchant.xianniuzu.com/static/includes/data.php?op=login",

    //优惠卷
    save_coupon:'https://merchant.xianniuzu.com/static/includes/data.php?op=savecoupon',
    // save_coupon:'http://www.xnz.com/data.php?op=savecoupon',
    get_coupon_list:"https://merchant.xianniuzu.com/static/includes/data.php?op=getcouponlist",
    // get_coupon_list:"http://www.xnz.com/data.php?op=getcouponlist",
    disable_coupon:"https://merchant.xianniuzu.com/static/includes/data.php?op=disablecoupon",
    del_coupon:"https://merchant.xianniuzu.com/static/includes/data.php?op=delcoupon",
    get_coupon_detail:"https://merchant.xianniuzu.com/static/includes/data.php?op=getcoupondetail",
    // get_coupon_detail:"http://www.xnz.com/data.php?op=getcoupondetail",
    get_sel_plan:"https://merchant.xianniuzu.com/static/includes/data.php?op=getselplanlist",
    // get_sel_plan:"http://www.xnz.com/data.php?op=getselplanlist",


    //商户

    savemerchant:'https://merchant.xianniuzu.com/static/includes/data.php?op=savemerchant',
    //savemerchant:"http://www.xnz.com/data.php?op=savemerchant",
    merchantlist:'https://merchant.xianniuzu.com/static/includes/data.php?op=merchantlist',
    //merchantlist:'http://www.xnz.com/data.php?op=merchantlist',
    disablemerchant:'https://merchant.xianniuzu.com/static/includes/data.php?op=disablemerchant',
    enablemerchant:'https://merchant.xianniuzu.com/static/includes/data.php?op=enablemerchant',
    disableshop:'https://merchant.xianniuzu.com/static/includes/data.php?op=disableshop',
    enableshop:'https://merchant.xianniuzu.com/static/includes/data.php?op=enableshop',
    getmerchant:'https://merchant.xianniuzu.com/static/includes/data.php?op=getmerchant',
    //getmerchant:'http://www.xnz.com/data.php?op=getmerchant',
    allmerchant:"https://merchant.xianniuzu.com/static/includes/data.php?op=allmerchant",
    agreementpay:"https://merchant.xianniuzu.com/static/includes/data.php?op=agreementpay",

    //风控
    saveriskmsg:'https://merchant.xianniuzu.com/static/includes/data.php?op=saveriskmsg',
    editriskmsg:"https://merchant.xianniuzu.com/static/includes/data.php?op=editriskmsg",
    pushmsg:"https://merchant.xianniuzu.com/static/includes/data.php?op=pushmsg",

    //提现
    savecashout:'https://merchant.xianniuzu.com/static/includes/data.php?op=savecashout',
    addcashout:'https://merchant.xianniuzu.com/static/includes/data.php?op=addcashout',
    cashoutlist:"https://merchant.xianniuzu.com/static/includes/data.php?op=cashoutlist",
    getcashout:"https://merchant.xianniuzu.com/static/includes/data.php?op=getcashout",
    updatecashout:"https://merchant.xianniuzu.com/static/includes/data.php?op=updatecashout",
    cashoutlog:"https://merchant.xianniuzu.com/static/includes/data.php?op=cashoutlog",
    completecashout:"https://merchant.xianniuzu.com/static/includes/data.php?op=completecashout",
    checkcashout:"https://merchant.xianniuzu.com/static/includes/data.php?op=checkcashout",

    //结算
    savesettle:"https://merchant.xianniuzu.com/static/includes/data.php?op=savesettle",
    getsettle:"https://merchant.xianniuzu.com/static/includes/data.php?op=getsettle",
    sncinfo:"https://merchant.xianniuzu.com/static/includes/data.php?op=sncinfo",
    alterorderrate:"https://merchant.xianniuzu.com/static/includes/data.php?op=alterorderrate",

    //调拨
    targetpdt:"https://merchant.xianniuzu.com/static/includes/data.php?op=targetpdt",
    getassociate:"https://merchant.xianniuzu.com/static/includes/data.php?op=getassociate",
    saveass:"https://merchant.xianniuzu.com/static/includes/data.php?op=saveass",
    getasslist:"https://merchant.xianniuzu.com/static/includes/data.php?op=getasslist",
    changeassstatus:"https://merchant.xianniuzu.com/static/includes/data.php?op=changeassstatus",
    associatepdt:"https://merchant.xianniuzu.com/static/includes/data.php?op=associatepdt",
    delass:"https://merchant.xianniuzu.com/static/includes/data.php?op=delass",
    getassdetail:"https://merchant.xianniuzu.com/static/includes/data.php?op=getassdetail",
    allocatelist:"https://merchant.xianniuzu.com/static/includes/data.php?op=allocatelist",
    //allocatelist:"http://www.xnz.com/data.php?op=allocatelist",
    getassmerchant:"https://merchant.xianniuzu.com/static/includes/data.php?op=getassmerchant",
    saveallocate:"https://merchant.xianniuzu.com/static/includes/data.php?op=saveallocate",
    rejectallocate:"https://merchant.xianniuzu.com/static/includes/data.php?op=rejectallocate",
    //rejectallocate:"http://www.xnz.com/data.php?op=rejectallocate",
    allocatelog:"https://merchant.xianniuzu.com/static/includes/data.php?op=allocatelog",
    getallorder:"https://merchant.xianniuzu.com/static/includes/data.php?op=getallorder",
    asslog:"https://merchant.xianniuzu.com/static/includes/data.php?op=asslog",

    //生成商品二维码
    //get_qrcode:"http://www.xnzj.com/datatwo.php?op=getQRcode",
    get_qrcode:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=getQRcode",
    //获取身份证是否存在
    //get_identity_id :"http://www.xnzj.com/datatwo.php?op=getIdentityId",
    get_identity_id :"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=getIdentityId",
    //获取交易快照
    // get_trades:"http://www.xnz.com/datatwo.php?op=gettrades",
    get_trades:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=gettrades",
    //增加只有管理员才能删除商品权限
    get_operator_control:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=getcontrol",
    //上传保险
    insurance:"https://merchant.xianniuzu.com/static/includes/insurance.php?option=main",
    //商户注册
    merchant_user:"https://merchant.xianniuzu.com/static/includes/data.php?op=merchant_user",
    //merchant_user:"http://www.xnz.com/data.php?op=merchant_user",
    
    //店铺数据
    statistics :"https://merchant.xianniuzu.com/static/includes/statistics.php?option=statistics",
    //查询店铺数据
    searchstatistics:"https://merchant.xianniuzu.com/static/includes/statistics.php?option=search",
    //验签报告
    // cunzheng:"http://www.xnz.com/datatwo.php?op=cunzheng",
    cunzheng:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=cunzheng",
    //入驻商家
    merchantruzhu:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=merchantruzhu",
    //还款详情
    huankuandetail:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=huankuandetail",
    //扣款
    handmoney:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=handmoney",
    //扣款返回的数据
    cutmoney:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=cutmoney",
    //审核店铺
    shenhe:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=shenhe",
    //添加商品详情活动
    addgoodsdetail:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=addgoodsdetail",
    // addgoodsdetail:"http://www.xnz.com/datatwo.php?op=addgoodsdetail",
    //商品详情活动列表
    // goodsdetail:"http://www.xnz.com/datatwo.php?op=goodsdetail",
    goodsdetail:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=goodsdetail",
    //查看商户
    lookmerchant:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=lookmerchant",
    //编辑活动
    getedit:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=getedit",
    //删除活动
    deldetail:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=deldetail",

    //保障服务
    getguarantdetail:"https://merchant.xianniuzu.com/static/includes/guarant.php?op=getguarant",
    getguarantlist:"https://merchant.xianniuzu.com/static/includes/guarant.php?op=getguarantlist",
    saveitem:"https://merchant.xianniuzu.com/static/includes/guarant.php?op=saveitem",
    saveodd:"https://merchant.xianniuzu.com/static/includes/guarant.php?op=saveodd",
    odddel:"https://merchant.xianniuzu.com/static/includes/guarant.php?op=odddel",
    //手动获取风控
    handrisk:"https://merchant.xianniuzu.com/alimini/handrisk.php",
    //更新身份证
    updatecard:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=updatecard",
    //取消订单发送短信
    cancelmsg:"https://merchant.xianniuzu.com/sendmsg2.php",
    //导出数据
    // exportdata:"http://www.xnz.com/data.php?op=exportdata",
    exportdata:"https://merchant.xianniuzu.com/static/includes/data.php?op=exportdata",
    //移动账单
    movebill:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=movebill",
    //移动账单2
    movebill2:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=movebill2",
    //退全款
    fullrefund:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=fullrefund",
    //部分退款详情
    getrefundbill:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=getrefundbill",
    //可退款查询
    searchrefund:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=searchrefund",
    //获取授权单号
    getauth:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=getauth",
    //修改状态
    editstatus:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=editstatus",
    //溢价率
    premiumrate:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=premiumrate",
    //续租
    relet:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=relet",
    //驳回
    bohui:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=bohui",
    //个人资料
    personinfo:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=personinfo",
    //保存个人资料
    savepersoninfo:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=savepersoninfo",
    //商户资料
    merchantinfo:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=merchantinfo",
    //获取发票
    invoice:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=invoice",
    //发票申请
    invoiceapply:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=invoiceapply",
    //一键申请
    getinvoice:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=getinvoice",
    //申请开发票
    invoicelist:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=invoicelist",
    //完成开票
    completeinvoice:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=completeinvoice",
    //查看开票详情
    getidinvo:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=getidinvo",
    //提现
    alipay:"https://merchant.xianniuzu.com/static/includes/alipayrequest.php?op=alipay",
    //修改押金
    Deposit3:"https://merchant.xianniuzu.com/static/includes/datatwo.php?op=Deposit3",
}